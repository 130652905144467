import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookie = new Cookies();

function getApiHost()
{
    let hostname = window.location.hostname;
    if (
        hostname === 'localhost' ||
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)
    )
    {
        hostname = hostname + ':5000';
    }
    return window.location.protocol + '//' + hostname;
}

export const apiHost = getApiHost();

export const apiAddress = apiHost + '/api/';

const instance = axios.create({
    baseURL: apiAddress,
    timeout: 40000
});

instance.interceptors.request.use(function (config) {
    const token = cookie.get("access_token");
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 && error.request.responseURL.indexOf('api/users/login') === -1) {
        // logout status
        cookie.remove('user_info', {path: '/'});
        cookie.remove('access_token', {path: '/'});
        window.location.href = '/signin';
    }
    throw error;
});

export const login = (username, password, thirdTokenData) => {
    const url = '/users/login';
    const req = {
        username,
        password,
        thirdTokenData
    };
    return instance.post(url, req).then((response) => response.data);
}

export function logout() {
    const url = '/users/logout';
    return instance.get(url).then((response) => response.data);
}

export const getUserInfo = (token) => {
    const url = '/users/info';
    return instance.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => response.data);
}

export function flowerHistory() {
    const url = `/flowers/paid-histories`;
    return instance.get(url).then((response) => response.data);
}

export function chargeHistoryAdd(data) {
    const url = `/charge-histories`;
    return instance.post(url, data).then((response) => response.data);
}

export function chargeHistoryList() {
    const url = `/charge-histories`;
    return instance.get(url).then((response) => response.data);
}

export function chargeHistoryRefund(id) {
    const url = `/charge-histories/${id}/refund-request`;
    return instance.get(url).then((response) => response.data);
}

export const checkThirdUser = (type, data) => {
    const url = '/users/check-third-user'
    return instance.post(url, {type, data}).then((response) => response.data);
}

export function termsInfo() {
    const url = `/settings/terms`;
    return instance.get(url).then((response) => response.data);
}


