import {Cookies} from 'react-cookie';
import {
    LOGIN_USER,
    LOGOUT_USER,
} from 'Store/types';

const cookie = new Cookies();

const INIT_STATE = {
    userInfo: cookie.get("user_info") ? cookie.get("user_info") : {},
    accessToken: cookie.get("access_token"),
    isLogin: !!cookie.get("access_token")
};

const authUser = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                userInfo: action.payload.info,
                accessToken: action.payload.accessToken,
                isLogin: true,
            };
        case LOGOUT_USER:
            return { ...state, accessToken: '', isLogin: false };
        default:
            return { ...state };
    }
}

export default authUser;