import React, {useState, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as Apis from 'Service/api';

function PaymentResult() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const imp_success = searchParams.get('imp_success');
        const imp_uid = searchParams.get('imp_uid');
        const merchant_uid = searchParams.get('merchant_uid');
        const amount = searchParams.get('amount');
        const flower = searchParams.get('flower');
        const error_msg = searchParams.get('error_msg');
        setResult({imp_success, imp_uid, merchant_uid, amount, flower, error_msg})
        if(imp_success === 'true') {
            Apis.chargeHistoryAdd({imp_uid, merchant_uid, amount, flower}).then((resp) => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <div className="ondot wh--full">
            <div id="header" className="header">
                <div className="section__container disp--flex f-jc--between f-ai--center">
                    <h1 className="header__logo"><a href="/"><img src={require("Assets/img/ondot_Logo.svg").default} alt="logo"/></a></h1>
                    <nav className="ml-auto">
                        <ul className="sns__wrap">
                            <li className="sns__li"><a href=""><img src={require("Assets/img/facebook.svg").default} className="main_icon" alt="페이스북"/><img
                                src={require("Assets/img/facebook_gray.svg").default} alt="페이스북"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/blog.svg").default} className="main_icon" alt="블로그"/>
                                <img src={require("Assets/img/blog_gray.svg").default} alt="블로그"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/instagram.svg").default} className="main_icon" alt="인스타그램"/><img
                                src={require("Assets/img/instagram_gray.svg").default} alt="인스타그램"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/youtube.svg").default} className="main_icon" alt="유투브"/><img
                                src={require("Assets/img/youtube_gray.svg").default} alt="유투브"/></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div style={{padding: '1em'}}>
                {/*<div>주문번호: {searchParams.get('imp_uid')}</div>*/}
                {/*<div>결제요청번호: {searchParams.get('merchant_uid')}</div>*/}
                {/*<div>결과: {searchParams.get('imp_success')}</div>*/}
                {/*<div>사유: {searchParams.get('error_msg')}</div>*/}
                {
                    loading ? <div>로딩중</div> :
                        (result.imp_success === 'true' ?
                            <div className="charge__wrap">
                                <div className="charge__box">
                                    <div className={'charge-result'}>
                                        <ion-icon name={"checkmark-circle"}/>
                                        <span className={'result-title'}>결제성공</span>
                                        <span className={'result-desc'}>결제에 성공하였습니다.</span>
                                        <div className={'flower-container'}>
                                            <img src={require('Assets/img/ic_flower.svg').default} alt={''}/>
                                            <span className={'result-flower'}>{result.flower}송이</span>
                                        </div>
                                        <span className={'result-content'}>플라워 충전이 완료되었습니다.</span>
                                        <button onClick={() => navigate('/')}>홈으로</button>
                                    </div>
                                </div>
                            </div>:
                            <div className="charge__wrap">
                                <div className="charge__box">
                                    <div className={'charge-result'}>
                                        <ion-icon name={"alert-circle"} style={{color: '#ec1b23'}}/>
                                        <span className={'result-title'}>결제실패</span>
                                        <span className={'result-desc'}>죄송합니다. 결제가 실패하였습니다.</span>
                                        <span className={'result-content'} style={{marginTop: 70}}>사유: {result.error_msg}</span>
                                        <button onClick={() => navigate('/payment')}>다시 결제하기</button>
                                    </div>
                                </div>
                            </div>
                        )

                }
            </div>
        </div>
    )
}

export default PaymentResult;