import React, {useEffect} from 'react';
import {useNavigate, useParams, useLocation, useSearchParams} from "react-router-dom";
import * as Apis from 'Service/api';
import {useDispatch} from "react-redux";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {loginUser} from "../../Store/Actions";

function ThirdLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {type} = useParams();
    const [searchParams] = useSearchParams();

    useEffect( () => {
        getThirdInfo();
    }, []);

    const getThirdInfo = async () => {
        try {
            let data;
            if (type === 'naver') {
                const token = location.hash.split('=')[1].split('&')[0];
                data = {accessToken: token};
            } else if (type === 'kakao') {
                const code = searchParams.get('code');
                const clientId = '1715c7ad5667d63c3a97de837bd12569';
                const kakaoResp = await axios.post(`https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${clientId}&redirect_uri=${window.location.origin}/third-login/kakao&code=${code}`, {
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                    }
                })
                console.log(kakaoResp, 'kakaoresp')
                data = {accessToken: kakaoResp.data.access_token};
            } else if (type === 'google') {
                const idToken = searchParams.get('tokenId');
                data = {idToken};
            } else if (type === 'apple') {
                const code = searchParams.get('code');
                data = {authorizationCode: code, device: 'WEB'};
            } else {
                navigate('/login', {replace: true});
                return;
            }
            console.log(type, data);
            const resp = await Apis.login(null, null, {type, data})
            dispatch(loginUser(resp.info, resp.token, navigate));
        } catch (e) {
            NotificationManager.error('로그인이 실패했습니다.');
            // console.log(e)
            navigate('/login', {replace: true});
        }
    }

    return (
        <div>

        </div>
    )
}

export default ThirdLogin