import {BrowserRouter} from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
import {Provider} from 'react-redux';
import 'Assets/scss';
import {configureStore} from 'Store';
import App from 'Container';

function MainApp() {
    return (
        <Provider store={configureStore()}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
            <NotificationContainer/>
        </Provider>
    );
}

export default MainApp;
