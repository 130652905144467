import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import moment from 'moment';
import * as Apis from 'Service/api';
import {NotificationManager} from "react-notifications";

function History() {
    const navigate = useNavigate();
    const [historyList, setHistoryList] = useState([]);
    const [flower, setFlower] = useState(0);
    const [flowerHistories, setFlowerHistories] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.chargeHistoryList().then((resp) => {
            setHistoryList(resp);
        });
        Apis.flowerHistory().then((resp) => {
            setFlower(resp.flower);
            setFlowerHistories(resp.history);
        });
    }

    const onRefundRequest = (id) => {
        Apis.chargeHistoryRefund(id).then((resp) => {
            getData();
        }).catch(e => {
            NotificationManager.error('환불요청이 실패했습니다.');
        });
    }

    const renderItem = (item, index) => {
        return (
            <div key={index} className={'history-item'}>
                <div>{index + 1}</div>
                <div>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{item.chargeAmount}원</div>
                <div>{item.chargeDesc}</div>
                <div>
                    {
                        item.chargeStatus === 'SUCCESS' ?
                            <Button variant="outlined" color="error" size={'small'} onClick={() => onRefundRequest(item.id)}>
                                환불요청
                            </Button> :
                            <Button variant="text" color="error" size={'small'} disabled style={{color: 'red'}}>
                                환불처리중
                            </Button>
                    }
                </div>
            </div>
        )
    }

    const renderFlowerHistoryItem = (item, index) => {
        return (
            <div key={index} className={'flower-history-item'}>
                <div>{index + 1}</div>
                <div>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{item.value}송이</div>
                <div>{item.content}</div>
            </div>
        )
    }

    return (
        <div className="ondot wh--full">
            <div id="header" className="header">
                <div className="section__container disp--flex f-jc--between f-ai--center">
                    <h1 className="header__logo"><a href="/"><img src={require("Assets/img/ondot_Logo.svg").default} alt="logo"/></a></h1>
                    <nav className="ml-auto">
                        <ul className="sns__wrap">
                            <li className="sns__li"><a href=""><img src={require("Assets/img/facebook.svg").default} className="main_icon" alt="페이스북"/><img
                                src={require("Assets/img/facebook_gray.svg").default} alt="페이스북"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/blog.svg").default} className="main_icon" alt="블로그"/>
                                <img src={require("Assets/img/blog_gray.svg").default} alt="블로그"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/instagram.svg").default} className="main_icon" alt="인스타그램"/><img
                                src={require("Assets/img/instagram_gray.svg").default} alt="인스타그램"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/youtube.svg").default} className="main_icon" alt="유투브"/><img
                                src={require("Assets/img/youtube_gray.svg").default} alt="유투브"/></a></li>
                        </ul>
                    </nav>
                </div>
                <div className="charge__wrap">
                    <div className="charge__box">
                        <div className={'mb-4'}>
                            <span style={{fontWeight: '600'}}>현재보유플라워 <span style={{color: 'red'}}>{flower}</span>송이</span>
                        </div>
                        <div className="charge-history">
                            <div className={'charge-home-line'}>
                                <div className={'history-title'}>결제이력</div>
                                <Button variant="text" size={'small'} onClick={() => navigate(-1)}>뒤로가기</Button>
                            </div>
                            {
                                historyList.map((v, i) => renderItem(v, i))
                            }
                        </div>
                        <div className="charge-history mt-5">
                            <div className={'charge-home-line'}>
                                <div className={'history-title'}>플라워이용이력</div>
                            </div>
                            <div className={'flower-history'}>
                            {
                                flowerHistories.length === 0 ?
                                    <span style={{fontSize: 12}}>플라워이용이력이 존재하지 않습니다.</span> :
                                    flowerHistories.map((v, i) => renderFlowerHistoryItem(v, i))
                            }
                            </div>
                        </div>
                    </div>
                    <div className="flex1-auto footer__info" style={{marginTop: 50}}>
                        <p className="cmt">
                            <span style={{cursor: 'pointer'}} onClick={() => navigate('/privacy')}>개인정보처리방침</span>
                            <span style={{cursor: 'pointer'}} onClick={() => navigate('/terms')}>이용약관</span>
                            <span style={{cursor: 'pointer'}} onClick={() => navigate('/customer')}>고객센터</span>
                        </p>
                        <p className="cmt">
                            <span>대표자 : 황동석</span>
                            <span>주소 : 서울특별시 서초구 법원로3길 6-1, 302호 (태지빌딩)</span>
                            <span>사업자번호 : 351-88-02184</span>
                            <span>E-mail : infos@ontec.co.kr</span>
                            <span>대표번호 : 02) 595-5077</span>
                            <span>통신판매업신고번호 : 제 2022-서울서초-2009 호</span>
                        </p>
                        <p className="cmt copyright">Copyright 주식회사 온텍. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History;