import {Cookies} from 'react-cookie';
import {
    LOGIN_USER,
    LOGOUT_USER,
} from 'Store/types';
import * as Apis from 'Service/api';

const cookie = new Cookies();

export const loginUser = (info, accessToken, navigate) => (dispatch) => {
    if (Apis.apiHost.indexOf(':') !== -1) {
        cookie.set('user_info', info, {path: '/'});
        cookie.set('access_token', accessToken, {path: '/'});
    }
    dispatch({
        type: LOGIN_USER,
        payload: {
            info: info,
            accessToken: accessToken,
        }
    });
    navigate('/');
}

export const logoutUser = () => (dispatch) => {
    if (Apis.apiHost.indexOf(':') !== -1) {
        cookie.remove('user_info', {path: '/'});
        cookie.remove('access_token', {path: '/'});
    }
    dispatch({type: LOGOUT_USER});
}

