import React, {useState, useEffect} from 'react'

function Customer() {
    const [text, setText] = useState('');

    useEffect(() => {
    }, []);


    return (
        <div>
            <div id="header" className="header">
                <div className="section__container disp--flex f-jc--between f-ai--center">
                    <h1 className="header__logo"><a href="/"><img src={require("Assets/img/ondot_Logo.svg").default} alt="logo"/></a></h1>
                    <nav className="ml-auto">
                        <ul className="sns__wrap">
                            <li className="sns__li"><a href=""><img src={require("Assets/img/facebook.svg").default} className="main_icon" alt="페이스북"/><img
                                src={require("Assets/img/facebook_gray.svg").default} alt="페이스북"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/blog.svg").default} className="main_icon" alt="블로그"/>
                                <img src={require("Assets/img/blog_gray.svg").default} alt="블로그"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/instagram.svg").default} className="main_icon" alt="인스타그램"/><img
                                src={require("Assets/img/instagram_gray.svg").default} alt="인스타그램"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/youtube.svg").default} className="main_icon" alt="유투브"/><img
                                src={require("Assets/img/youtube_gray.svg").default} alt="유투브"/></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="charge__wrap">
                <div className="charge__box customer-container">
                    <div className={'customer-title'}>고객센터</div>
                    <div className={'d-flex flex-column'}>
                        <div className={'row-title'}>취소/환불 규정</div>
                        <div className={'d-flex flex-column'}>
                            <span className={'row-item'}>• 판매상품 내 서비스 제공기간: 1년</span>
                            <span className={'row-item'}>• 서비스 이용기간이나 정책에 대한 내용기재</span>
                        </div>
                    </div>
                    <div className={'d-flex flex-column mt-3'}>
                        <div className={'row-title'}>민원책임문고</div>
                        <div className={'d-flex flex-column'}>
                            <span className={'row-item'}>• 온닷에서 운영하는 사이트 내 판매되는 모든 상품은 온닷에서 책임지고 있습니다.</span>
                            <span className={'row-item'}>• 민원 담당자 황동석/연락처 010-5555-6408</span>
                        </div>
                    </div>
                </div>
                <div className="flex1-auto footer__info" style={{marginTop: 50}}>
                    <p className="cmt">
                        <span>대표자 : 황동석</span>
                        <span>주소 : 서울특별시 서초구 법원로3길 6-1, 302호 (태지빌딩)</span>
                        <span>사업자번호 : 351-88-02184</span>
                        <span>E-mail : infos@ontec.co.kr</span>
                        <span>대표번호 : 02) 595-5077</span>
                        <span>통신판매업신고번호 : 제 2022-서울서초-2009 호</span>
                    </p>
                    <p className="cmt copyright">Copyright 주식회사 온텍. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Customer;