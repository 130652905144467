import React, {useState, useEffect, useRef} from "react";
import {useNavigate } from "react-router-dom";
import {useDispatch} from 'react-redux';
import {NotificationManager} from "react-notifications";
import { GoogleLogin} from "react-google-login";
import AppleLogin from 'react-apple-login'
import * as Apis from "Service/api";
import {loginUser,} from 'Store/Actions';
import {Helmet} from "react-helmet";

function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const naverButtonRef = useRef();
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initializeNaverLogin();
    }, []);

    const initializeNaverLogin = () => {
        const {naver} = window;
        const naverLogin = new naver.LoginWithNaverId({
            clientId: 'ldhHghGgKZN6EabaCseO',
            callbackUrl: window.location.origin + '/third-login/naver',
            isPopup: false, // popup 형식으로 띄울것인지 설정
            loginButton: { color: 'green', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
        });
        naverLogin.init();
    };

    const kakaoLogin = () => {
        const clientId = '1715c7ad5667d63c3a97de837bd12569';
        const redirectUri = window.location.origin + '/third-login/kakao';
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
    }

    const onUserLogin = () => {
        if (id && password) {
            Apis.login(id, password).then((result) => {
                setLoading(false);
                dispatch(loginUser(result.info, result.token, navigate));
            }).catch((e) => {
                NotificationManager.error('아이디나 암호가 정확하지 않습니다');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <div className="ondot wh--full">
            <div id="header" className="header">
                <div className="section__container disp--flex f-jc--between f-ai--center">
                    <h1 className="header__logo"><a href="/"><img src={require("Assets/img/ondot_Logo.svg").default} alt="logo"/></a></h1>
                    <nav className="ml-auto">
                        <ul className="sns__wrap">
                            <li className="sns__li"><a href=""><img src={require("Assets/img/facebook.svg").default} className="main_icon" alt="페이스북"/><img
                                src={require("Assets/img/facebook_gray.svg").default} alt="페이스북"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/blog.svg").default} className="main_icon" alt="블로그"/><img src={require("Assets/img/blog_gray.svg").default}
                                                                                                                                                       alt="블로그"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/instagram.svg").default} className="main_icon" alt="인스타그램"/><img
                                src={require("Assets/img/instagram_gray.svg").default} alt="인스타그램"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/youtube.svg").default} className="main_icon" alt="유투브"/><img
                                src={require("Assets/img/youtube_gray.svg").default} alt="유투브"/></a></li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="login__wrap disp--flex f-ai--center pd10 wh--full f-dir--col">
                <div className="mb10 pd10 login__logo"><img src={require("Assets/img/ondot_logo_kr.svg").default} alt="온닷"/></div>
                <ul className="login">
                    <li>
                        <input
                            type="text"
                            className="input"
                            title="아이디"
                            placeholder="이메일"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                    </li>
                    <li>
                        <input
                            type="password"
                            className="input"
                            title="비밀번호"
                            placeholder="비밀번호"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </li>

                    <li>
                        <button type="button" className="btn btn--primary btn--full" onClick={onUserLogin}>로그인</button>
                    </li>
                </ul>
                <div className="login__sns">
                    <ul>
                        <li><a href="#" onClick={() => naverButtonRef.current.firstChild.click()}><img src={require("Assets/img/login_naver.svg").default} alt="네이버로그인"/></a></li>
                        <li><a href="#" onClick={() => kakaoLogin()}><img src={require("Assets/img/login_kakao.svg").default} alt="카카오로그인"/></a></li>
                        <li>
                            <GoogleLogin
                                clientId="1032267720178-hplvhon7p3mqs71alttc1fe7hufctts8.apps.googleusercontent.com"
                                buttonText="Login"
                                render={renderProps => (
                                    <a href="" onClick={renderProps.onClick}><img src={require("Assets/img/login_google.svg").default} alt="구글로그인"/></a>
                                )}
                                onSuccess={(resp) => navigate('/third-login/google?tokenId=' + resp.tokenId)}
                                onFailure={(resp) => console.error(resp)}
                                cookiePolicy={"single_host_origin"}
                            />
                        </li>
                        <li>
                            <AppleLogin
                                clientId="ondot.ontec.com"
                                redirectURI="https://ondot.kr/third-login/apple"
                                responseType={'code'}
                                responseMode={'query'}
                                render={(renderProps) => (
                                    <a href="" onClick={renderProps.onClick}><img src={require("Assets/img/login_apple.svg").default} alt="애플로그인"/></a>
                                )}
                            />
                            {/*<a href=""><img src={require("Assets/img/login_apple.svg").default} alt="애플로그인"/></a>*/}
                        </li>
                    </ul>
                </div>
                <div id='naverIdLogin' ref={naverButtonRef} style={{display: 'none'}}/>
            </div>

        </div>
    )
}

export default Home;