import React, {useState} from 'react';
import {Navigate, Route, Routes, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Login from 'Routes/login';
import ThirdLogin from 'Routes/login/ThirdLogin';
import Payment from 'Routes/payment';
import PaymentResult from 'Routes/payment/result';
import PaymentHistory from 'Routes/payment/history';
import Privacy from "Routes/nologin/privacy";
import Terms from "Routes/nologin/terms";
import Customer from "Routes/nologin/customer";

function GoLink({path}) {
    window.location.href = path;
    return (<div/>);
}


function PrivateRoute({ children }) {
    const authUser = useSelector(state => state.authUser);
    const {isLogin} = authUser;
    return isLogin ? children : <Navigate to="/login" />;
}

function App(props) {
    return (
        <Routes>
            {/*<Route index element={<Navigate to={'buymars'}/>}/>*/}
            <Route path="/" element={<GoLink path={'/home.html'} />}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/third-login/:type" element={<ThirdLogin/>}/>
            <Route path="/payment" element={<PrivateRoute><Payment/></PrivateRoute>}/>
            <Route path="/payment-result" element={<PrivateRoute><PaymentResult/></PrivateRoute>}/>
            <Route path="/payment-history" element={<PrivateRoute><PaymentHistory/></PrivateRoute>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/customer" element={<Customer/>}/>
        </Routes>
    );
}

export default App;
