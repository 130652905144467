import React, {useState, useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import Button from '@mui/material/Button';
import * as Apis from 'Service/api';
import {useNavigate} from "react-router-dom";

const priceList = [
    {flower: 200, price: 2000},
    {flower: 500, price: 5000},
    {flower: 1000, price: 10000},
    {flower: 5000, price: 50000},
    {flower: 10000, price: 100000},
];

function Home() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [selectedPrice, setSelectedPrice] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.getUserInfo().then((resp) => {
            setUserInfo(resp);
        });
    }

    const onSelectPrice = (value) => {
        setSelectedPrice(value);
    }

    const onChargeFlower = (type) => {
        const {IMP} = window;
        IMP.init("imp88172071");
        let query = `?amount=${selectedPrice.price}&flower=${selectedPrice.flower}`;
        IMP.request_pay({ // param
            pg: "html5_inicis",
            pay_method: type,
            merchant_uid: uuidv4(),
            name: `플라워 ${selectedPrice.flower.toLocaleString('en-US')}송이 구매`,
            amount: selectedPrice.price,
            buyer_email: userInfo.email,
            buyer_name: userInfo.username,
            buyer_tel: userInfo.phoneNumber,
            popup: true,
            m_redirect_url: window.location.origin + '/payment-result' + query,
        }, (resp) => { // callback
            console.log('payment result', resp);
            query += `&imp_uid=${resp.imp_uid}&merchant_uid=${resp.merchant_uid}&imp_success=${resp.success ? 'true' : 'false'}&error_msg=${resp.error_msg}`
            navigate('/payment-result' + query);
        });
    }


    return (
        <div className="ondot wh--full">
            <div id="header" className="header">
                <div className="section__container disp--flex f-jc--between f-ai--center">
                    <h1 className="header__logo"><a href="/"><img src={require("Assets/img/ondot_Logo.svg").default} alt="logo"/></a></h1>
                    <nav className="ml-auto">
                        <ul className="sns__wrap">
                            <li className="sns__li"><a href=""><img src={require("Assets/img/facebook.svg").default} className="main_icon" alt="페이스북"/><img
                                src={require("Assets/img/facebook_gray.svg").default} alt="페이스북"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/blog.svg").default} className="main_icon" alt="블로그"/>
                                <img src={require("Assets/img/blog_gray.svg").default} alt="블로그"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/instagram.svg").default} className="main_icon" alt="인스타그램"/><img
                                src={require("Assets/img/instagram_gray.svg").default} alt="인스타그램"/></a></li>
                            <li className="sns__li"><a href=""><img src={require("Assets/img/youtube.svg").default} className="main_icon" alt="유투브"/><img
                                src={require("Assets/img/youtube_gray.svg").default} alt="유투브"/></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            {
                !selectedPrice ?
                    <div className="charge__wrap">
                        <div className="charge__box">
                            <div className={'charge-home-line'}>
                                <p className="cmt">현재 보유 플라워 포인트: <span>{userInfo.userFlower}</span>송이</p>
                                <Button variant="text" size={'small'} onClick={() => navigate('/payment-history')}>결제이력</Button>
                            </div>
                            <ul className="charge__list">
                                {
                                    priceList.map((v) => (
                                        <li key={v.flower}>
                                            <button type="button" className="btn btn--full" onClick={() => onSelectPrice(v)}>
                                                <span className="pay--flower">{v.flower.toLocaleString('en-US')}송이</span>
                                                <span className="pay--won">{v.price.toLocaleString('en-US')}원</span>
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <p className="support">
                            <img src={require("Assets/img/world_vision.svg").default} alt="worldvision"/>
                            <span>온닷의 매달 수익의 일부분은 나눔사업에 쓰여집니다.</span>
                        </p>
                        <div className="flex1-auto footer__info" style={{marginTop: 50}}>
                            <p className="cmt">
                                <span style={{cursor: 'pointer'}} onClick={() => navigate('/privacy')}>개인정보처리방침</span>
                                <span style={{cursor: 'pointer'}} onClick={() => navigate('/terms')}>이용약관</span>
                                <span style={{cursor: 'pointer'}} onClick={() => navigate('/customer')}>고객센터</span>
                            </p>
                            <p className="cmt">
                                <span>대표자 : 황동석</span>
                                <span>주소 : 서울특별시 서초구 법원로3길 6-1, 302호 (태지빌딩)</span>
                                <span>사업자번호 : 351-88-02184</span>
                                <span>E-mail : infos@ontec.co.kr</span>
                                <span>대표번호 : 02) 595-5077</span>
                                <span>통신판매업신고번호 : 제 2022-서울서초-2009 호</span>
                            </p>
                            <p className="cmt copyright">Copyright 주식회사 온텍. All rights reserved.</p>
                        </div>
                    </div> :
                    <div className="charge__wrap">
                        <div className={'charge-price'}>
                            <div className={'price-info-top'}>
                                결제정보
                            </div>
                            <div className={'price-info'}>
                                <span className={'flower-value'}>{selectedPrice.flower.toLocaleString('en-US')}송이</span>
                                <span className={'price-value'}>{selectedPrice.price.toLocaleString('en-US')}₩</span>
                            </div>
                            <div className={'pay-buttons'}>
                                <button className={'pay-button'} onClick={() => onChargeFlower('card')}>
                                    <ion-icon name="card-outline"></ion-icon>
                                    <span>신용/체크카드</span>
                                </button>
                                <button className={'pay-button'} onClick={() => onChargeFlower('phone')}>
                                    <ion-icon name="phone-portrait-outline"></ion-icon>
                                    <span>휴대폰</span>
                                </button>
                            </div>
                            <div className={'charge-cancel'}>
                                <button className={'cancel-button'} onClick={() => setSelectedPrice(null)}>
                                    <ion-icon name="arrow-back-outline"></ion-icon>
                                    <span>뒤로</span>
                                </button>
                            </div>
                            <div className="flex1-auto footer__info" style={{marginTop: 50}}>
                                <p className="cmt">
                                    <span>대표자 : 황동석</span>
                                    <span>주소 : 서울특별시 서초구 법원로3길 6-1, 302호 (태지빌딩)</span>
                                    <span>사업자번호 : 351-88-02184</span>
                                    <span>E-mail : infos@ontec.co.kr</span>
                                    <span>대표번호 : 02) 595-5077</span>
                                    <span>통신판매업신고번호 : 제 2022-서울서초-2009 호</span>
                                </p>
                                <p className="cmt copyright">Copyright 주식회사 온텍. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default Home;